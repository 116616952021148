<template>
  <b-container fluid>
    <b-row>
      <input
        style="display: none"
        ref="fileInput"
        type="file"
        @change="previewImage"
        :accept="fileFormat"
      />

      <div class="image-preview" v-if="partialFile !== ''">
        <b-img fluid-grow class="preview" :src="partialFile" />
      </div>
      <div class="image-preview" v-else-if="pdfName !== ''">
        {{ pdfName }}
      </div>
    </b-row>

    <b-row>
      <b-col v-if="isFileUploaded" class="text-center mt-3">
        <b-button variant="danger" pill
          size="lg"  @click="clearFiles" class="mr-2"
          ><em class="text-dark fas fa-times-circle" /></b-button>
        <b-button variant="success" pill
          size="lg" @click="confirmFile">
          <em class="text-dark fas fa-check-circle" />
        </b-button>
      </b-col>
    </b-row>
  </b-container>
</template>

<script>
import { resizeImg } from "@/utils/resizeImage"
export default {
  name: "ImageUploader",
  props: {
    uploadFile:{
      type:String
    },
    fileFormat:{
      type:String,
      default: 'image/*'
    }
  },
  data() {
    return {
      partialFile: "",
      file: "",
      pdfName: ''
    }
  },
  watch: {
    uploadFile(value) {
      if (value === "file") {
        this.$refs.fileInput.click()
        this.$emit("file-browser-open", null)
      } else {
        this.clearFiles()
      }
    },
  },
  methods: {
    confirmFile() {
      if(!this.file.type.includes('pdf')){
        var imageUrl = URL.createObjectURL(this.file)
        var image = new Image()
        image.src = imageUrl
        image.onload = () => {
          this.$emit("confirm-file", resizeImg(image, 620, 480, 0))
        }
      }else{
        this.$emit("confirm-file", this.file)
      }
      this.$bvModal.hide("picture-modal")
    },
    clearFiles() {
      this.partialFile = ""
      this.pdfName = ""
    },
    previewImage: function (event) {
      var input = event.target
      this.file = input.files[0]
      if (input.files && input.files[0]) {
        var reader = new FileReader()

        reader.onload = (e) => {
          if(!this.file.type.includes('pdf')){
            this.partialFile = e.target.result
          } else {
            this.pdfName = this.file.name;
          }
        }
        // Start the reader job - read file as a data url (base64 format)
        reader.readAsDataURL(input.files[0])
      }
    },
  },
  computed:{
    isFileUploaded(){
      return this.pdfName || this.partialFile;
    }
  },
  beforeUpdate() {
    if (this.uploadFile) {
      this.clearFiles()
    }
  },
}
</script>

<style>
.file-upload-form,
.image-preview {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  padding: 20px;
}
img.preview {
  width: 200px;
  background-color: white;
  border: 1px solid #ddd;
  padding: 5px;
}
.confirmPictureButton {
  margin-top: -70px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: rgba(0, 0, 0, 0.7);
}
</style>